<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table   height="calc(100vh - 230px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
      <el-table-column width="153" prop="name" label="佣金规则名称" >
      </el-table-column>
      <el-table-column width="107" prop="mode" label="分销分佣比例" >
        <template #default="scope">
             {{scope.row.income_rate}} %
        </template>
      </el-table-column>
      <el-table-column width="107" prop="mode" label="上级分佣比例" >
        <template #default="scope">
             {{scope.row.higher_income_rate}} %
        </template>
      </el-table-column>
      <el-table-column width="107"  prop="mode" label="开启自购分佣" >
        <template #default="scope">
             <el-tag size="mini" v-if="scope.row.is_self_buy_income==1">开启</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="107" prop="mode" label="自购分佣比例" >
        <template #default="scope">
          {{scope.row.self_buy_rate}} %
        </template>
      </el-table-column>
      <el-table-column prop="mode" label="结算方式" >
        <template #default="scope">
           <span v-for="(item,i) in modeArr">
             <span v-if="item.id==scope.row.settlement_mode">{{item.name}}</span>
           </span>
        </template>
      </el-table-column>
      <el-table-column width="100" prop="settlement_date" label="月结日期" >
      </el-table-column>
      <el-table-column width="180" show-overflow-tooltip prop="income_rate_tip" label="分销分佣描述" >
      </el-table-column>
      <el-table-column width="180" show-overflow-tooltip prop="higher_income_rate_tip" label="上级分佣描述" >
      </el-table-column>
      <el-table-column width="180" show-overflow-tooltip prop="settlement_tip" label="结算描述" >
      </el-table-column>
      <el-table-column width="180" show-overflow-tooltip prop="description" label="佣金结算说明" >
      </el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" width="125">
          <template #header>
            <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="40%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="padding:0 15px;height:calc(100% - 30px) ">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm">
        <el-form-item label="佣金规则名称" prop="name">
          <el-input  v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="开启自购分佣" prop="is_self_buy_income">
          <el-select style="width: 100%" @change="modefun" v-model="ruleForm.is_self_buy_income" >
            <el-option
                v-for="item in incomeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自购分佣比例" prop="self_buy_rate">
          <el-input-number v-model="ruleForm.self_buy_rate" style="width: 100%" :min="0" controls-position="right" ></el-input-number>
        </el-form-item>
        <el-form-item label="分销分佣比例" prop="income_rate">
          <el-input-number v-model="ruleForm.income_rate" style="width: 100%" :min="0" controls-position="right" ></el-input-number>
        </el-form-item>
        <el-form-item label="上级分佣比例" prop="higher_income_rate">
          <el-input-number v-model="ruleForm.higher_income_rate" style="width: 100%" :min="0" controls-position="right" ></el-input-number>
        </el-form-item>
        <el-form-item label="结算方式" prop="settlement_mode">
          <el-select style="width: 100%" @change="modefun" v-model="ruleForm.settlement_mode" >
            <el-option
                v-for="item in modeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.settlement_mode==2" label="月结日期" prop="settlement_date">
          <el-date-picker style="width: 100%" value-format="YYYY-MM-DD" v-model="ruleForm.settlement_date" type="date" placeholder="">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="分销分佣描述" prop="">
          <el-input  type="textarea" :rows="2" v-model="ruleForm.income_rate_tip"></el-input>
        </el-form-item>
        <el-form-item label="上级分佣描述" prop="">
          <el-input  type="textarea" :rows="2" v-model="ruleForm.higher_income_rate_tip"></el-input>
        </el-form-item>
        <el-form-item label="结算描述" prop="">
          <el-input  type="textarea" :rows="2" v-model="ruleForm.settlement_tip"></el-input>
        </el-form-item>
        <el-form-item label="佣金结算说明" prop="">
          <el-input  type="textarea" :rows="2" v-model="ruleForm.description"></el-input>
        </el-form-item>

      </el-form>
    </el-scrollbar>
      <div  style="text-align: right">
        <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
        <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import { getArea } from "@/api/common";
import * as axios from "@/api/skuApi"//本页面接口
export default {
  name: 'Grade',
  data(){
    return{
      skuValName:'',
      login:false,
      drawer:false,
      tableData:[],
      areaArr:[],//城市
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        name:'',
        is_self_buy_income:0,
        self_buy_rate:0,
        income_rate:0,
        higher_income_rate:0,
        settlement_mode:'',
        settlement_date:'',
        income_rate_tip:'',
        higher_income_rate_tip:'',
        settlement_tip:'',
        description:'',
      },
      modeArr:[
        {
          id:0,
          name:'订单完成',
        },
        {
          id:1,
          name:'日结',
        },
        {
          id:2,
          name:'月结',
        },
      ],
      incomeArr:[
        {
          id:1,
          name:'开启',
        },
        {
          id:0,
          name:'关闭',
        },
      ],
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        start_date: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        end_date: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.onTable()
    this.onArea()
    // this.keyupSubmit()
  },
  methods:{
    // keyupSubmit(){
    //   document.onkeydown=e=>{
    //     let _key=window.event.keyCode;
    //     if(_key===13){
    //       this.search()
    //     }
    //   }
    // },
    modefun(){
      this.ruleForm.times=0
      this.ruleForm.start_date=''
      this.ruleForm.end_date=''
    },
    onArea(){
        getArea().then((res)=>{
          this.areaArr=res.data
        }).catch((err)=>{

        })
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getSkuData(form,'/api/admin/member/commission').then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },


    add(){
      this.ruleForm={
        name:'',
        is_self_buy_income:0,
        self_buy_rate:0,
        income_rate:0,
        higher_income_rate:0,
        settlement_mode:'',
        settlement_date:'',
        income_rate_tip:'',
        higher_income_rate_tip:'',
        settlement_tip:'',
        description:'',
      }
      this.drawer=true
    },
    upData(row){
      console.log(row)
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.is_self_buy_income=row.is_self_buy_income
      this.ruleForm.self_buy_rate=Number(row.self_buy_rate)
      this.ruleForm.income_rate=Number(row.income_rate)
      this.ruleForm.higher_income_rate=Number(row.higher_income_rate)
      this.ruleForm.settlement_mode=row.settlement_mode
      this.ruleForm.settlement_date=row.settlement_date
      this.ruleForm.income_rate_tip=row.income_rate_tip
      this.ruleForm.higher_income_rate_tip=row.higher_income_rate_tip
      this.ruleForm.settlement_tip=row.settlement_tip
      this.ruleForm.description=row.description
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postSkuData(this.ruleForm,'/api/admin/member/commission/add').then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.postSkuData(this.ruleForm,'/api/admin/member/commission/edit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postSkuData({id:row.id},'/api/admin/member/commission/del').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  }
}
</script>
<style lang="less" >
</style>